.main-body{
    min-height: 65vh;
}

.accordion-button{
    font-size: 18px !important; 
}
.accordion-header{
    margin-top:0px;
}
.para-product{
    text-align: left;
    height: 105px;
    width: fit-content;
    min-width: 450px;
    max-width: 100%;
    margin: 0px;
    display: inline-block;
    
}

@media (max-width:900px) {
    .para-product{
        text-align: left;
        height: 105px;
        width: fit-content !important;
        min-width: auto;
        margin: 0px;
    }
    .actuLogin{
        flex-direction: column;
        align-items: center;
    }
    .actuLogin div{
        width: 80% !important; 
        padding-bottom: 15px;
    }
    .actuLogin div img{
        width: 80% !important; 
    }
}

.product-table{
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this.*/
    width: stretch; 
}
.product-table .details-div{
    text-align: center;
}

.product-table td{
    padding: 10px 20px 10px 20px;
}