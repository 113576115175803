.footer {
    background: #99e5ff;
    padding: 10px 0;
    display: grid;
    grid-template-rows: repeat(8, min-content) 1fr;
    grid-template-columns: 100%;
    left:0;
    bottom:0;
    right:0;
    clear: both;
    position: relative;
  }
  .footer a {
    color: #70726F;
    font-size: 20px;
    padding: 10px;
    border-right: 1px solid #70726F;
    transition: all .5s ease;
  }
  .footer  a:first-child {
    border-left: 1px solid #70726F;
  }
  .footer a:hover {
    color: white;
  }