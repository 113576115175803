.App {
  text-align: center;
  background-color:white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.p3 {
  
  padding: 1em;
}

.table-div {
  display: inline-block;
  height: 30em;
  width:100%;
  overflow-y: auto;
  overflow-x: auto;
}
.table-div thead th{
  /* border: 1px solid; */
  position: sticky;
  top: 0;
  background-color:#215870;
}
/* .table-div tbody td{
  background-color:#fdfeff; 
   border: 1px solid;
} */
.table-div caption {
  position: sticky;
}

th {
  text-align:center !important
}

.navbar:before{
  content: none !important
}
.navbar:after{
  content: none !important
}
#navbarContainer:before{
  content: none !important

}
#navbarContainer:after{
  content: none !important

}


.accueilLink:link {
  text-decoration: none;
}

.accueilLink:visited {
  text-decoration: none;
}

.accueilLink:hover {
  text-decoration: none;
}

.accueilLink:active {
  text-decoration: none;
}
.navbar-nav{
  margin: 5px 5px !important
}
.videoSoco {
  width: 60%;
}
.nav-link
{
  
  font-size: calc(1.275rem + .3vw)!important;
}
@media (min-width:768px) {
  .nav-link {
    color:#fff !important;
  }
}

@media (max-width:768px) {
  .select-div{
    display: flex;
    flex-direction:column;
  }
  .select-second-item{
    align-self: center;
  }
  .videoSoco {
    width: 90%;
  }
}

@media (max-width:630px) {
  .header-picture-div{
    padding-right: 1em !important;
  }
}


input::placeholder {

  opacity: 1;
  color: black;
  
}

.navbar-toggler span {
  height: 2.5em;
  width: 2.5em;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cstyle%3Eline %7B stroke-width: 8px%3B stroke: %23fff%3B %7D%3C/style%3E%3Cline x1='0' y1='25' x2='100' y2='25' /%3E%3Cline x1='0' y1='50' x2='100' y2='50' /%3E%3Cline x1='0' y1='75' x2='100' y2='75' /%3E%3C/svg%3E") ! important

}
.nav-item{
  margin-right:10px
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spoiler {
  background-color: black;
  color: black;
  transition: color 0.3s;
  padding: 5px;
  border-radius: 5px;
}

.spoiler:hover {
  color: white;
}
